@use "@angular/material" as mat;
@use 'variables';

h2.uni {
  font: 2rem "Merriweather";
}

footer.uni {
  background-color: variables.$uni-primary;
}

:root {
  h1.uni {
    font: 400 1rem / 1.5rem "Roboto";
  }
  
  .uni {
    @include mat.button-overrides((
      filled-container-color: variables.$uni-primary
    ));

    @include mat.toolbar-overrides((
      container-background-color: variables.$uni-primary
    ));

    @include mat.list-overrides((
      list-item-label-text-color: black
    ));

    @include mat.expansion-overrides((
      container-background-color: white
    ));

    span a {
      color: white;
    }
  }

  .uni-tabs {
    @include mat.toolbar-overrides((
      container-background-color: white
    ));

    .mat-mdc-list-item::after {
      border-bottom: 4px solid variables.$uni-gray;
    }

    .mat-mdc-list-item.isActive::after,
    .mat-mdc-list-item:active::after {
        border-bottom: 4px solid variables.$uni-secondary;
    }
  }
}