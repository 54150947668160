@use "@angular/material" as mat;
@use 'variables';

#toolbar-nav-container.iastate {
  background-color: variables.$iastate-gray;
}

h2.iastate {
  font: 2rem "Merriweather";
  color: variables.$iastate-primary;
}

footer.iastate {
  background-color: variables.$iastate-primary;
}

mat-toolbar.iastate {
  border-bottom: 1px solid variables.$light-gray;
}

:root {
  h1.iastate {
    font: 400 1rem / 1.5rem "Roboto";
    color: variables.$iastate-primary;
  }
  
  .iastate {
    @include mat.button-overrides((
      filled-container-color: variables.$iastate-primary
    ));

    @include mat.form-field-overrides((
      outlined-focus-outline-color: black,
      outlined-focus-label-text-color: black
    ));

    @include mat.select-overrides((
      enabled-trigger-text-color: black,
      focused-arrow-color: black
    ));

    @include mat.toolbar-overrides((
      container-background-color: white
    ));

    @include mat.list-overrides((
      list-item-label-text-color: variables.$iastate-primary
    ));

    @include mat.expansion-overrides((
      container-background-color: variables.$iastate-gray
    ));

    span a {
      color: white;
    }
  }

  .iastate-tabs {
    @include mat.toolbar-overrides((
      container-background-color: variables.$iastate-gray
    ));

    .mat-mdc-list-item::after {
      border-bottom: 4px solid variables.$iastate-primary;
    }

    .mat-mdc-list-item.isActive::after,
    .mat-mdc-list-item:active::after {
        border-bottom: 4px solid variables.$iastate-secondary;
    }
  }
}