@use "@angular/material" as mat;
@use 'variables';

h2.regents {
  font: 2rem "Merriweather";
}

footer.regents {
  background-color: variables.$regents-primary;
}

.logo--footer span h2 {
  font-family: "Lato";
  color: white;
}

:root {
  h1.regents {
    font: 400 1rem / 1.5rem "Roboto";
  }

  .regents-tabs {
    @include mat.toolbar-overrides((
      container-background-color: white,
      title-text-size: 16px
    ));
  }
  
  .regents-bar {
    @include mat.toolbar-overrides((
      container-background-color: variables.$regents-primary,
      standard-height: 50px
    )); 

    .mat-toolbar-single-row {
      height: 50px;
    }

    .site-name {
      height: 50px;
    }
  }

  .regents {
    @include mat.button-overrides((
      filled-label-text-color: white
    ));

    @include mat.expansion-overrides((
      container-background-color: white
    ));

    span a {
      color: white;
    }
  }
}