@use 'variables';
@use "mixins";
@use "@angular/material" as mat;
@use '../public/assets/theme/theme' as theme;

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(/assets/fonts/roboto/Roboto-Regular.ttf) format("truetype");
}

*,
html,
body {
  box-sizing: border-box;
}

html,
body {
  min-height: 100% !important;
  margin: 0;
  padding: 0;
  font-family: variables.$default-font-family;
  font-size: variables.$default-font-size; // clamp(16px, 1.5vw, 1rem);
  line-height: variables.$default-line-height; // clamp(16px, 1.5vw, 1rem);
  -webkit-font-smoothing: antialiased;
}

h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Global Classes */
.elevated-container {
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #0000001F
}


.element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.content {
  max-width: 87.5rem;
  padding: 0 2.5rem;
}

.link {
  color: variables.$regents-primary;
}

button:hover,
a:hover {
  cursor: pointer;
}

button a:visited {
  color: inherit;
}

button a {
  text-decoration: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.padding-none {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.padding {
  padding: 10px .5em !important;
}

.padding-top {
  padding-top: 10px !important;
}

.padding-left {
  padding-left: .5em !important;
}

.padding-right {
  padding-right: .5em !important;
}

.padding-bottom {
  padding-bottom: 10px !important;
}

.padding-md-top {
  padding-top: 20px !important;
}

.padding-md-left {
  padding-left: 1em !important;
}

.padding-md-right {
  padding-right: 1em !important;
}

.padding-md-bottom {
  padding-bottom: 20px !important;
}

/* Margin TOP */
.margin-top {
  margin-top: 40px !important;
}
.margin-sm-top {
  margin-top: 20px !important;
}
.margin-xsm-top {
  margin-top: 10px !important;
}

/* Margin RIGHT */
.margin-right {
  margin-right: 1.5em !important;
}
.margin-xsm-right {
  margin-right: .5rem !important;
}
.margin-sm-right {
  margin-right: .75em !important;
}

/* Margin LEFT */
.margin-left {
  margin-left: 1.5em !important;
}
.margin-sm-left {
  margin-left: .75em !important;
}

/* Margin BOTTOM */
.margin-bottom {
  margin-bottom: 40px !important;
}
.margin-sm-bottom {
  margin-bottom: 20px !important;
}

/* Margin ALL */
.margin-none {
  margin: 0 !important;
}
.margin {
  margin: 40px 1.5em !important;
}
.margin-sm {
  margin: 20px .75em !important;
}

/* Margin LEFT & RIGHT */
.margin-lr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-row,
.flex-xs-row,
.flex-sm-row,
.flex-md-row,
.flex-lg-row {
  display: flex !important;
  flex-direction: row !important;
}

.grid-3-25-sm-row {
  display: grid !important;
  grid-template-columns: 25% 25% 25%;
}
.grid-2-25-sm-row {
  display: grid !important;
  grid-template-columns: 25% 25%;
}
.grid-2-50-row {
  display: grid !important;
  grid-template-columns: 50% 50%;
}

.grid-2-25-50-sm-row {
  display: grid !important;
  grid-template-columns: 25% 25%;
}
.grid-col-gap-sm {
  column-gap: 1.5rem;
}

.center,
.flex-center {
  justify-content: center !important;
}

.flex-start {
  justify-content: flex-start !important;
}

.flex-end {
  justify-content: flex-end !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.space-between,
.flex-space-between {
  justify-content: space-between !important;
}

.space-around,
.flex-spae-around {
  justify-content: space-around !important;
}

.space-evenly,
.flex.space-evenly{
  justify-content: space-evenly;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.center-text  {
  text-align: center;
}

.right-text {
  text-align: right;
}

.height-100 {
  height: 100%;
}

.width-xs-10,
.width-sm-10,
.width-md-10,
.lg-width-10 {
  width: 10%;
}

.width-xs-25,
.width-sm-25,
.width-md-25,
.width-lg-25 {
  width: 25%;
}

.width-50,
.width-xs-50,
.width-sm-50,
.width-md-50,
.width-lg-50 {
  width: 50%;
}

.width-xs-75,
.width-sm-75,
.width-md-75,
.width-lg-75 {
  width: 75%;
  max-width: 1400px;
}

.width-100,
.width-xs-100,
.width-sm-100,
.width-md-100,
.width-lg-100  {
  width: 100%;
  max-width: 1400px;
}

.width-auto {
  width: auto;
}

@media (max-width: 959.98px) {
  .width-xs-10,
  .width-sm-10,
  .width-md-10,
  .lg-width-10 {
    width: 50%;
  }
  
  .width-xs-25,
  .width-sm-25,
  .width-md-25,
  .width-lg-25 {
    width: 50%;
  }
  
  .width-50,
  .width-xs-50,
  .width-sm-50,
  .width-md-50,
  .width-lg-50 {
    width: 100%;
  }
  
  .width-xs-75,
  .width-sm-75,
  .width-md-75,
  .width-lg-75 {
    width: 100%;
    max-width: 1400px;
  }
  
  .width-100,
  .width-xs-100,
  .width-sm-100,
  .width-md-100,
  .width-lg-100  {
    width: 100%;
    max-width: 1400px;
  }
}

.height-100 {
  height: 100vh;
}

.mobile {
  display: none !important;
}

.display-none {
  display: none;
}

.offscreen {
  position: absolute;
  overflow: hidden;
  left: -9999px;
}

.small-table-cell {
  width: 32px
}

.white {
  color: white;
}

.black {
  color: black;
}

.red {
  color: variables.$brand-red;
}

.cool-gray {
  color: variables.$brand-cool-gray;
}

.no-underline {
  text-decoration: none;
}

.bar-content {
  max-width: 87.5rem;
  padding: 0 2.5rem;
}

.gray-text {
  color: variables.$brand-cool-gray;
}

.gray-border {
  border: 2px solid #E1E1E1;
}

.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  max-width: fit-content;
}

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.mat-toolbar-single-row {
   padding: 0;
}

.mat-mdc-tab-header {
  width: 50%;
}

.mat-mdc-option.mdc-list-item--selected {
  background-color: variables.$light-gray !important;
}

.mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text {
  color: black !important;
}

.mdc-button__label {
  display: flex;
}

.mat-expansion-panel {
  box-shadow: none !important;
  border: 2px solid variables.$light-gray;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
  color: white !important;
}

.mat-mdc-dialog-content {
  box-shadow: inset 0 -10px 10px 0 variables.$light-gray;
}

.brand-bar-container {
  background-color: variables.$regents-primary;
}

:root {
  @include mat.menu-overrides((
    container-color: white
  ));

  @include mat.button-toggle-overrides((
    selected-state-background-color: variables.$light-blue,
    selected-state-text-color: black,
    shape: 4px
  ));

  @include mat.checkbox-overrides((
    selected-checkmark-color: white
  ));

  @include mat.form-field-overrides((
    outlined-disabled-outline-color: variables.$light-gray,
    outlined-disabled-label-text-color: variables.$brand-cool-gray,
    container-height: 56px
  ));

  @include mat.datepicker-overrides((
    calendar-date-selected-state-text-color: white,
    calendar-date-selected-state-background-color: variables.$regents-primary,
  ));

  @include mat.dialog-overrides((
    with-actions-content-padding: 0 40px,
    actions-padding: 40px,
    actions-alignment: left,
    container-color: white
  ));

  @include mat.list-overrides((
    list-item-label-text-weight: 600
  ));

  @include mat.select-overrides((
    panel-background-color: white
  ));

  @include mat.button-overrides((
    outlined-disabled-outline-color: variables.$light-gray,
    outlined-disabled-label-text-color: variables.$brand-cool-gray,
    filled-container-color: variables.$regents-primary,
    filled-label-text-color: white,
    filled-container-shape: 4px,
    outlined-container-shape: 4px
  ));

  .secondary-button {
    @include mat.button-overrides((
      filled-container-color: variables.$blue
    ));
  }

  .delete-button {
    @include mat.icon-overrides((
      color: variables.$brand-red
    ));
  }

  .mat-mdc-outlined-button {
    width: auto;
    min-height: 40px;
  }

  .mdc-button {
    line-height: normal;
  }

  @include mat.paginator-overrides((
    container-background-color: white
  ));

  @include mat.table-overrides((
    row-item-outline-width: .5,
    row-item-outline-color: variables.$light-gray,
    background-color: white,
    header-headline-color: black,
    header-headline-size: title-medium,
    row-item-label-text-color: black,
    row-item-label-text-size: title-medium
  ));

  @include mat.expansion-overrides((
    container-shape: 4px,
    container-background-color: white
  ));

  .mat-mdc-header-row {
    background: variables.$light-gray;
  }

  .mat-mdc-table {
    border: 1px solid variables.$light-gray;
    border-radius: 4px;
  }

  .mat-sort-header-arrow {
    color: black;
  }
  
  .brand-bar {
    max-width: 87.5rem;
    
    @include mat.toolbar-overrides((
      container-background-color: variables.$regents-primary,
      standard-height: 50px
    )); 

    .mat-toolbar-single-row {
      height: 50px;
    }

    .site-name {
      height: 50px;
    }
  }

  @include mat.divider-overrides((
    color: variables.$gray
  ));

  span a {
    color: white;
  }
}
