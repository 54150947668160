/* variables for theme overrides */
$light-gray: #EDEDED;
$gray: #E2E2E2;
$light-blue: #C6EEFF;
$blue: #008BC7;
$gold: #CAA00A;

/* regents colors */
$regents-primary: #233c7c;

/* UIOWA colors */
$uiowa-primary: #ffcd00;

/* IASTATE colors */
$iastate-primary: #C8102E;
$iastate-secondary: #F1BE48;
$iastate-gray: #F5F5F5;

/* UNI colors */
$uni-primary: #500778;
$uni-secondary: #FFB500;
$uni-gray: #E4E4E4;

/* use with white text */
$brand-cool-gray: #63666a;
$brand-blue: #00558c;
$brand-green: #00664f;
$brand-red: #bd472a;

/* font size */
$default-font-size: 16px;
$default-font-family: "Roboto", helvetica, arial, sans-serif;
$default-line-height: 1.5rem;

/** shared proportions **/
$column-width: 5%;
$border-radius: 5px;
$default-spacing: 10px;

/** breakpoints **/
$mobile-max: 959px;
$desktop-min: 959.1px;
