@use "@angular/material" as mat;
@use 'variables';

h2.uiowa {
  font: 2.5rem "Zilla Slab";
}

:root {
  .uiowa {
    @include mat.button-overrides((
      filled-label-text-font: "Antonio",
      filled-label-text-color: black,
      filled-container-color: variables.$uiowa-primary
    ));

    span a {
      color: black;
    }

    @include mat.form-field-overrides((
      outlined-focus-outline-color: black,
      outlined-focus-label-text-color: black
    ));

    @include mat.select-overrides((
      enabled-trigger-text-color: black,
      focused-arrow-color: black
    ));

    @include mat.card-overrides((
      outlined-container-color: #F2DEDE,
      outlined-container-shape: 4px,
      outlined-outline-width: 0,
      subtitle-text-color: #A94442
    ));

    .mat-mdc-card-subtitle {
      margin: 16px;
    }
  }
}